/* @import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css'; */
@import '@fontsource/poppins/200.css';
@import '@fontsource/poppins/300.css';
@import '@fontsource/poppins/400.css';
@import '@fontsource/poppins/500.css';
@import '@fontsource/poppins/600.css';
@import '@fontsource/poppins/700.css';
@import '@fontsource/poppins/800.css';
@import '@fontsource/poppins/900.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 224 71.4% 4.1%;

        --card: 0 0% 100%;
        --card-foreground: 224 71.4% 4.1%;

        --popover: 0 0% 100%;
        --popover-foreground: 224 71.4% 4.1%;

        --primary: 238.70000000000005, 83.5%, 66.7%; /* indigo-500 */
        --primary-foreground: 0, 0%, 100%; /* white */

        --secondary: 216, 12.2%, 83.9%; /* gray-300 */
        --secondary-foreground: 220, 13%, 91%; /* gray-200 */

        --muted: 220 14.3% 95.9%;
        --muted-foreground: 220 8.9% 46.1%;

        --accent: 220 14.3% 95.9%;
        --accent-foreground: 220.9 39.3% 11%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 20% 98%;

        --border: 220 13% 91%;
        --input: 220 13% 91%;
        --ring: 224 71.4% 4.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71.4% 4.1%;
        --foreground: 210 20% 98%;

        --card: 224 71.4% 4.1%;
        --card-foreground: 210 20% 98%;

        --popover: 224 71.4% 4.1%;
        --popover-foreground: 210 20% 98%;

        --primary: 210 20% 98%;
        --primary-foreground: 220.9 39.3% 11%;

        --secondary: 215 27.9% 16.9%;
        --secondary-foreground: 210 20% 98%;

        --muted: 215 27.9% 16.9%;
        --muted-foreground: 217.9 10.6% 64.9%;

        --accent: 215 27.9% 16.9%;
        --accent-foreground: 210 20% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 20% 98%;

        --border: 215 27.9% 16.9%;
        --input: 215 27.9% 16.9%;
        --ring: 216 12.2% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    html {
        scroll-behavior: smooth;
    }

    body {
        @apply bg-background text-foreground;
        font-family: 'Poppins', 'ui-sans-serif', 'system-ui';
        font-weight: 200;
        background: rgb(243 244 246);
    }

    /* HeroCards background shadow */
    .shadow {
        position: absolute;
        background: #4338CA;
        opacity: 0;
        border-radius: 24px;
        rotate: 35deg;
        z-index: -1;
        width: 260px;
        top: 200px;
        height: 400px;
        filter: blur(150px);
        will-change: transform;
        animation: shadow-slide infinite 4s linear alternate;
    }

    @keyframes shadow-slide {
        from {
            background: #4338CA;
            opacity: .2;
            right: 460px;
        }
        to {
            background: #4338CA;
            opacity: .8;
            right: 160px;
        }
    }

    @media (max-width: 1024px) {
        .shadow {
            top: 70px;
        }

        @keyframes shadow-slide {
            from {
                background: #4338CA;
                opacity: .2;
                right: 460px;
            }
            to {
                background: #4338CA;
                opacity: .5;
                right: 160px;
            }
        }
    }

    @media (max-width: 768px) {
        .shadow {
            top: 70px;
            width: 100px;
            height: 350px;
            filter: blur(60px);
        }

        @keyframes shadow-slide {
            from {
                background: #4338CA;
                opacity: .2;
                right: 280px;
            }
            to {
                background: #4338CA;
                opacity: .3;
                right: 100px;
            }
        }
    }
}